.home-bg {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  opacity: 1;
  object-fit: cover;
  z-index: -1;
}

.showcase {
  position: absolute;
  top: 100vh;
  width: 100vw;
  color: black;
}

.showcase-banner-div {
  display: grid;
  width: 100%;
}

.banner-under-construction-div {
  grid-row: 1;
  grid-column: 1;
  opacity: .33;
}

.under-construction-div {
  grid-row: 1;
  grid-column: 1;
  z-index: 10;
  align-self: center;
}

.under-construction-img {
  width: 100%;
}

.no-padding {
  padding: 0 !important;
  margin: 0 !important;
}